import { DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { init as initSentry } from '@sentry/angular';
import { AnalyticsApiModule, AnalyticsService } from '@neuralegion/analytics-api';
import {
  CAPTCHA_CONFIG_TOKEN,
  CLIENT_INFO_TOKEN,
  ENVIRONMENT_TOKEN,
  Environment,
  FILE_UPLOAD_CONFIG_TOKEN
} from '@neuralegion/api';
import { AuthModule } from '@neuralegion/auth';
import { AuthApiModule, AuthService } from '@neuralegion/auth-api';
import { AppRouterStateSerializer, CoreModule, appReducer } from '@neuralegion/core';
import {
  DefaultErrorReporterService,
  ERROR_HANDLERS_TOKEN,
  ErrorHandlerModule,
  ErrorHandlerWrapperService,
  ExternalErrorHandler,
  ExternalErrorReporterService,
  SentryErrorReporterService
} from '@neuralegion/error-handler';
import { ErrorPagesModule } from '@neuralegion/error-pages';
import { OrganizationApiModule } from '@neuralegion/organization-api';
import { ProfileApiModule } from '@neuralegion/profile-api';
import { RepeatersApiModule } from '@neuralegion/repeaters-api';
import { CookiePolicyService, ShareModule } from '@neuralegion/share';
import { StorageApiModule } from '@neuralegion/storage-api';
import { LoadChunkErrorHandler, UpdaterModule } from '@neuralegion/updater';
import { UserConsentsModule } from '@neuralegion/user-consents';
import { environment } from '../../environments/environment';
import { AppComponent, RootComponent } from '../containers';
import { appMetaReducer } from '../store';
import { AppRouterModule } from './app-routing.module';

if (environment.sentryDsn) {
  initSentry({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'development',
    release: environment.version
  });
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducer, {
      metaReducers: [appMetaReducer],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        // TODO make actions/state serializable
        /*strictStateSerializability: true,
        strictActionSerializability: true,*/
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    AppRouterModule,
    AnalyticsApiModule,
    AuthModule,
    CoreModule,
    ErrorHandlerModule,
    ErrorPagesModule,
    ShareModule,
    AuthApiModule,
    OrganizationApiModule,
    ProfileApiModule,
    RepeatersApiModule,
    StorageApiModule,
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PreActivation
    }),
    UserConsentsModule,
    UpdaterModule
  ],
  declarations: [AppComponent, RootComponent],
  bootstrap: [RootComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [CookiePolicyService],
      useFactory: (cookiePolicy: CookiePolicyService) => (): void => cookiePolicy.load(),
      multi: true
    },
    ...(environment.segmentWriteKey
      ? [
          {
            provide: APP_INITIALIZER,
            deps: [AnalyticsService],
            useFactory: (analytics: AnalyticsService) => (): void =>
              analytics.load(environment.segmentWriteKey),
            multi: true
          }
        ]
      : []),
    {
      provide: ExternalErrorReporterService,
      useClass: environment.sentryDsn ? SentryErrorReporterService : DefaultErrorReporterService
    },
    {
      provide: ERROR_HANDLERS_TOKEN,
      useClass: ExternalErrorHandler,
      multi: true
    },
    {
      provide: ERROR_HANDLERS_TOKEN,
      useClass: LoadChunkErrorHandler,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerWrapperService
    },
    {
      provide: APP_INITIALIZER,
      deps: [DOCUMENT],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useFactory: (doc: Document) => () => ((doc.defaultView as any).VERSION = environment.version),
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (authService: AuthService) => () => authService.checkSession(),
      multi: true
    },
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment
    },
    {
      provide: CLIENT_INFO_TOKEN,
      deps: [ENVIRONMENT_TOKEN],
      useFactory: (env: Environment) => ({
        source: 'ui',
        ...(env.version
          ? {
              client: {
                name: 'bright-ui',
                version: env.version
              }
            }
          : {})
      })
    },
    {
      provide: CAPTCHA_CONFIG_TOKEN,
      useValue: { reCaptchaSiteKey: environment.reCaptchaSiteKey, maxFailedAccessAttempts: 2 }
    },
    {
      provide: FILE_UPLOAD_CONFIG_TOKEN,
      useValue: { maxUploadSize: environment.archiveMaxFileSize || 500 * 1024 * 1024 }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        autoFocus: true,
        delayFocusTrap: true,
        restoreFocus: true
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' }
    },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: false
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500
      }
    },
    {
      provide: RouterStateSerializer,
      deps: [DOCUMENT],
      useFactory: (doc: Document) => new AppRouterStateSerializer(doc)
    }
  ]
})
export class AppModule {}
