export enum IssueCheckStatus {
  PASSED = 'Passed',
  FAILED = 'Failed',
  NOT_CHECKED = 'Not checked'
}

export interface IssueCheck {
  test: string;
  status: IssueCheckStatus;
}
